import {Component, CUSTOM_ELEMENTS_SCHEMA, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {Promotion} from '@app/models';
import {SwiperOptions} from 'swiper/types';
import {ChevronIcon} from '@app/icons';
import {PromotionCardComponent} from '@app/promotions/promotion-card/promotion-card.component';
import {RouterLink} from '@angular/router';
import {SwiperDirective} from '@directive/swiper.directive';

@Component({
  standalone: true,
  selector: 'promotion-slider',
  imports: [
    ChevronIcon,
    CommonModule,
    PromotionCardComponent,
    RouterLink,
    SwiperDirective,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  templateUrl: './promotion-slider.component.html',
  styleUrls: ['./promotion-slider.component.css'],
})
export class PromotionSliderComponent {
  @Input({required: true}) promotions: Promotion[] = [];

  config: SwiperOptions = {
    spaceBetween: 25,
    slidesPerView: 'auto',
    navigation: {
      prevEl: '#promo-swiper-prev',
      nextEl: '#promo-swiper-next',
    },
    scrollbar: {
      draggable: true,
      el: '.promotions-swiper-scrollbar',
      dragClass: 'bg-secondary-100 h-full rounded-xl',
    },
  };

  constructor() {}
}
