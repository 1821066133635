@if (promotions.length > 0) {
<div class="md:mx-5 mb-9 relative">
  <div class="p-9 pb-14 md:px-16 bg-light-gray rounded-sm">
    <button id="promo-swiper-prev"
      aria-label="Previous"
      class="btn-slider z-10 absolute top-1/2 -translate-y-1/2 left-0 md:left-4">
      <span class="block py-5 px-3 bg-primary-300">
        <i class="text-white">
          <icon-chevron [width]="16" [class]="'rotate-90'"></icon-chevron>
        </i>
      </span>
    </button>
    <div>
      <swiper-container #swiperRefPromotions swiperElement initial-slide="0" [config]="config" init="false" [scrollbar]="{
            el: '.promotions-swiper-scrollbar',
            draggable: true
          }">
        @for (promotion of promotions; track promotion.ID) {
        <swiper-slide class="text-left shadow-xl bg-off-white mb-3 promo">
          <app-promotion-card [promotion]="promotion"></app-promotion-card>
        </swiper-slide>
        }
      </swiper-container>
    </div>
    <div class="promotions-swiper-scrollbar swiper-scrollbar rounded mb-1"></div>
    <button id="promo-swiper-next"
      aria-label="Next"
      class="btn-slider z-10 absolute top-1/2 -translate-y-1/2 right-0 md:right-4">
      <span class="block py-5 px-3 bg-primary-300">
        <i class="text-white">
          <icon-chevron [width]="16" [class]="'-rotate-90'"></icon-chevron>
        </i>
      </span>
    </button>
  </div>
</div>
<div class="flex justify-center mb-14">
  <a routerLink="/promotions"
    class="btn block">view
    all promos</a>
</div>
}